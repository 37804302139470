<template>
  <v-container fluid>
    <FilterDrawer
      current-component="clients-filter"
      @filter="filterClients"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.id }}</td>
        <td>{{ item.company_name }}</td>
        <td>{{ item.trading_name }}</td>
        <td>
          <ContactInfo
            v-if="item.client_contact"
            :name="JSON.parse(item.client_contact)[0].name"
            :phone-one="JSON.parse(item.client_contact)[0].phone_number"
            :phone-two="JSON.parse(item.client_contact)[0].cel_number"
            :email="JSON.parse(item.client_contact)[0].email"
          />
          <span v-else>-</span>
        </td>
        <td class="text-center">
          <ClientMood :mood_id="item.mood_id" />
        </td>
        <td>{{ item.seller }}</td>
        <td>{{ item.agency }}</td>
        <td>
          <StatusChip :current-status="item.status" />
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import StatusChip from '@/components/data-table/StatusChip'
import ContactInfo from '@/components/data-table/ContactInfo'
import ClientMood from '@/components/data-table/ClientMood'
import FilterDrawer from '@/components/FilterDrawer'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    StatusChip,
    ContactInfo,
    ClientMood,
    FilterDrawer,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Clientes',
      new: 'clients-new',
      edit: 'clients-edit',
      context: 'clients-context',
      massAction: 'clients-mass-action',
      totalPages: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Código', value: 'id' },
        { text: 'Razão Social', value: 'company_name' },
        { text: 'Nome Fantasia', value: 'trading_name' },
        { text: 'Contato', value: 'client_contact' },
        { text: 'Humor', value: 'mood_id', align: 'center' },
        { text: 'Vendedor', value: 'seller', align: 'center' },
        { text: 'Agência', value: 'agency', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
    },
    user: null,
    profile: null,
  }),
  mounted() {
    this.getClients()

    contextMenu.$once('action', (type) => {
      if (type === 'clients') {
        this.setInitialData()
      }
    })
    massAction.$once('action', () => {
      this.setInitialData()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getClients(page)
    },
    search(search) {
      this.currentSearch = search
      this.getClients()
    },
    getClients(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('people/type/clients', {
          params: {
            page,
            search: this.currentSearch,
          },
        })
        .then((res) => {
          this.dataTable.items = res.data.data
          this.dataTable.totalPages = res.data.last_page;
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterClients(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('people/filter/client', filter)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getClients()
    },
  },
}
</script>
